const NAMESPACE = 'ffpcv_';

export const COLLECTIONS = {
  USERS: NAMESPACE + 'users',
  USERS_LICENSES: 'user_licenses',
  CLUBS: NAMESPACE + 'clubs',
  TEAMS: NAMESPACE + 'teams',
  COMPETITION: NAMESPACE + 'competitions',
  SEASON: NAMESPACE + 'seasons',
  SEASON_RATES: 'season_rates',
  MATCH: NAMESPACE + 'matches',
  MOVEMENTS: NAMESPACE + 'movements',
  LICENSES_RATES: NAMESPACE + 'licenses_rates',
  REQUESTS: NAMESPACE + 'requests',
  TRANSFERS: NAMESPACE + 'transfers',
  UNSUBSCRIBES: NAMESPACE + 'unsubscribes',
  JOURNEYS: 'season_journeys',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_TEMPLATES: 'notifications_templates',
  EMAILS: 'emails',
  EMAILS_TEMPLATES: 'emails_templates',
  MASTERS: 'masters',
  MASTERS_SETTINGS_DOC: 'settings',
};
